@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes shine {
    0% {
        background-position: -100% center;
        opacity: 0.7;
    }
    100% {
        background-position: 200% center;
        opacity: 1;
        background: none;
    }
}

.animate-fadeIn {
    animation: fadeIn 1s forwards;
}

.form-transition {
    transition: all 0.3s ease-out;
}

.word-appear {
    display: inline-block;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(0, 122, 255, 0.1),
        transparent
    );
    background-size: 200% 100%;
    animation: shine 0.5s ease-out forwards;
}

.word-visible {
    opacity: 1;
    display: inline-block;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
